import * as React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";

const GalleriePage = (props) => {
  // const data = props.data.allWuerfeYaml.edges;
  return (
    <>
      <Layout title="Gallerie">
        <main className="container">
          <h1>Gallerie</h1>
          {/* <div className="row g-2 justify-content-evenly">
            {data.reverse().map(({ node }) => (
              <>
                <GatsbyImage
                  className="shadow image-link"
                  quality={80}
                  image={node.mainImage.childImageSharp.gatsbyImageData}
                  alt={node.fullName}
                  objectFit="contain"
                  loading="lazy"
                />
                <h2 className="py-3">{node.fullName}</h2>
              </>
            ))}
          </div> */}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query GalleriePageQuery {
    allWuerfeYaml {
      edges {
        node {
          fullName
          mainImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default GalleriePage;
